import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';
import { Image } from 'nordic/image';

const ICON_ID = 'rep_seller_delivery_time_good_v2';
const namespace = 'ui-pdp-icon ui-pdp-icon--time-positive';

const IconTimePositiveV2 = ({ className }) => (
  <div className={classnames(namespace, className)}>
    <Image alt="" src="time-positive-v2.svg" />
  </div>
);

IconTimePositiveV2.propTypes = {
  className: string,
};

IconTimePositiveV2.defaultProps = {
  className: null,
};

IconTimePositiveV2.ICON_ID = ICON_ID;

export default React.memo(IconTimePositiveV2);
export { IconTimePositiveV2 };
